import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"
import ModalPop from "../components/modal/modal"

const ServicesPage = () => (
	<Layout>
		<SEO title="Our Services" description="" />

		<div className="page-hero services-page">
			<div className="hero-content">
				<h5 data-aos="fade" data-aos-duration="300" data-aos-delay="900">
					INTERMOUNTAIN BOILERWORX INC.
				</h5>
				<h1
					data-aos="fade-up"
					data-aos-duration="600"
					data-aos-delay="1200"
					className="font-weight-bolder mt-3 mt-md-5"
				>
					Our Services
				</h1>
				<h4 data-aos="fade-up " data-aos-duration="600" data-aos-delay="500">
					Intermountain BoilerworX,Inc. maintains professional relationships
					with excellent service providers and representatives who are experts
					in their fields for Chemical Treatment, NDE, Code Repairs, New
					Equipment, <br />
					and other special needs.{" "}
				</h4>
				<div class="d-md-block ">
					<Link to="/contact" className="btn-1 main-cta btn btn-secondary">
						Free Consultation
					</Link>
				</div>
			</div>
		</div>

		<div className="our-services services">
			<div className="container">
				<div className="row">
					<div className="col-md-6" />
					<div className="col-md-6">
						<div className="call-a-tech_content text-bg">
							<h2 className="text-white">Intermountain BoilerworX, Inc.</h2>
							<p>
								is a veteran-owned, and factory-trained Colorado based service
								provider for the entire Rocky Mountain region and neighboring
								areas. Our focus is on commercial and industrial boiler system
								service and repairs, and other industrial and special services,
								24 hours a day. We utilize a safety program that exceeds todays
								standards of the industry, meeting all of our clients needs. Our
								company is based on the belief that providing top quality
								service with a safe working environment is paramount. Whatever
								it takes.
							</p>
							<p className="text-white">
								Contact a Representative for current rates:{" "}
								<a href="tel:3038450432">(303) 845-0432</a>
							</p>
							<p>Territories:</p>
							<ul>
								<li>Rocky Mountain Region</li>
								<li>Great Plains Region</li>
							</ul>
							<p>Hours:</p>
							<ul>
								<li>Mon - Fri: 7AM - 5PM</li>
								<li>Saturday: On Call 303.845.0432 </li>
								<li>Sunday: On Call 303.845.0432</li>
							</ul>
							<div class="d-md-block">
								<Link
									to="/contact"
									className="btn-1 main-cta btn btn-secondary"
								>
									Free Consultation
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="services" />
		</div>

		<div className="footer-hero">
			<div className="d-sm-flex justify-content-center package ">
				<div
					data-aos="fade"
					data-aos-delay="300"
					className="package_type shadow aos-init"
				>
					<h3 className="gradient-secondary px-4 px-lg-5 py-3">Basic</h3>
					<ul className="list-unstyled styled-list text-darker">
						<li>Burners and controls</li>
						<li>Refractory services</li>
						<li>Steam Trap Troubleshooting</li>
						<li>Item Emergency repairs</li>
						<li>Tube replacement</li>
						<li>Onsite Training</li>
						<li>Safety Testing</li>
						<li>MULE Program (Machinery Useful Life Examinations)</li>
						<li>Annual Cleaning and Inspections</li>
						<li>Tune ups (ask about our 'Test and Tune' program)</li>
						<li>Equipment Replacement</li>
						<li>Traps and steam service</li>
						<li>Feed Water Systems</li>
						<li>Combustion management systems</li>
						<li>Efficiency Upgrades</li>
						<li>24 hour Tech. Support</li>
					</ul>
				</div>
				<div
					data-aos="fade"
					data-aos-delay="600"
					className="package_type shadow aos-init"
				>
					<h3 className="gradient px-4 px-lg-5 py-3">Special</h3>
					<ul className="list-unstyled styled-list text-darker">
						<li>Tube Cleaning</li>
						<li>Weld Services</li>
						<li>Plant Outages</li>
						<li>
							Heat Exchanger Services of all types including plate and frame
						</li>
						<li>Heat Exchanger Pressure testing</li>
						<li>Field-Machine Pipe Bevel Services</li>
						<li>Stub Removal</li>
						<li>Flange Facing</li>
						<li>Tube replacment</li>
						<li>Emergency Tube Plugging</li>
						<li>Pipe Services</li>
						<li>Boiler Rental</li>
						<li>Confined Space Services</li>
						<li>Ancillary Consulting</li>
					</ul>
				</div>
			</div>
			<h3 className="text-grey text-center">
				For 24-Hour Service: <a href="tel:3038450432">(303) 845-0432</a>
			</h3>
		</div>
	</Layout>
)

export default ServicesPage
